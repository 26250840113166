.full-width-image {
    width: 100%;
    position: relative;

}

.full-width-image img {
    width: 100%;
}

.nav-tabs .nav-link {
    font-size: 18px;
}
