.padmin {
    padding-left: 5px;
    padding-right: 5px;
  }
  .pd10 {
    padding: 10px;
  }
.productWrapper {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}

.addPad {
    padding: 10px 0 10px;
}

.prodImage {
    position: relative;
    width: 100%;
    height: 100%;
    border: solid 1px rgba(204, 204, 204, 0.6);
}

.prodTable {
    display: table;
    width: 100%;
}

.prodTable-100 {
    display: table;
    width: 100%;
    height: 100%;
}

.productCenter>img {
    height: 300px;
}

.prodCell {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
}

.prodCell-100 {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
}

.prodDescription {
    color: white;
    position: absolute;
    top: calc(50% - 7px);
    font-size: 14px;
    left: 0;
    display: none;
    text-align: center;
    width: 100%;
    z-index: 2;
}
.productCenter > img {
    height: 300px;
  }
@media (max-width: 767px) {
    .prodCell>img {
        max-height: 120px;
    }

}

@media (min-width: 768px) {
    .prodTable {
        min-height: 233px;
    }

    .prodTable-100 {
        max-height: 100px;
    }

    .prodCell>img {
        max-height: 220px;
    }
}

@media (min-width: 992px) {

    .prodCell>img {
        max-height: 200px;
    }

    .prodTable {
        max-height: 150px;
    }
}

@media (min-width: 1200px) {
    .prodCell>img {
        min-height: 201px
    }

}