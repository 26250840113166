body{
  padding-top: 3.5rem;
}
.active{
  color:red;
}

.pdtop20{
  padding-top: 20px;
}
@media (max-width: 767px) {
  .paddingCustom {
    padding: 0.5rem 10px;
  }
  .HideMobile {
    display: none;
  }
  .formMobile {
    width: 80%;
  }
  .HideMobile {
    display: none;
  }
}

@media (min-width: 768px) {
  .paddingCustom {
    padding: 0.5rem 13px;
  }
  .HideMobile {
    display: none;
  }
  .formMobile {
    width: 90%;
  }
  .HideMobile {
    display: none;
  }
}

@media (min-width: 992px) {
  .menuPaddingDesktop {
    padding: 0.5rem 100px;
  }
  .paddingCustom {
    padding: 0.5rem 100px;
  }
  .HideMobile {
    display: block;
  }
  .formMobile {
    display: none;
  }
  .HideMobile {
    display: block;
  }
}

  @media (min-width: 1200px) {

  }

  .spinner{
    position:fixed;
    left:50%;
    top:50%;
    margin:10px 0 10px;
    z-index:9999;
  }
  .link-right{
    display: inline-block;
    text-align: right;
    width: 100%;
  }
  .tangan{
    cursor: pointer;
  }
 
  /* .navbar-dark .navbar-nav .nav-link {
    color: #fff;
} */